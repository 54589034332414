import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import * as S from './styles';
import Box from './Box';

const Faq = () => {
  const faq = [
    {
      question: 'Can I cancel my ride?',
      answ: 'If you have already booked a ride and wish to cancel your transfer, you must do so at least 24 hours before the scheduled transfer time. Failure to cancel within this timeframe will result in the inability to cancel the ride, and the remaining amount will be charged to the card used for the initial payment.',
    },
    {
      question: "What if I don't show up?",
      answ: "In the event that you do not show up for your scheduled transfer without contacting us, you will be charged the remaining amount on the card you provided during the booking process. It's important to inform us of any changes or cancellations to avoid these charges.",
    },
    {
      question: 'What if my flight is delayed?',
      answ: 'No need to worry! Our professional drivers continuously monitor flight dashboards, ensuring they are informed of any delays. Rest assured, they will wait for you accordingly, so you can relax knowing your transportation needs are being taken care of, even in the event of a delay.',
    },
    {
      question: 'How do I recognize my driver?',
      answ: "Our drivers are easily identifiable, as they are always well-dressed and positioned at the exit immediately after luggage pickup, holding a sign with your name clearly written on it. You can trust that you'll be able to spot them effortlessly. However, if you encounter any difficulties locating your driver, please don't hesitate to contact us using the phone number provided in your confirmation email. We're here to assist you.",
    },
    {
      question: 'What are the accepted payment methods?',
      answ: 'The advance payment made during the booking process can be settled using a debit or credit card, including Visa, MasterCard, AMEX, and various others. For the remaining balance payable on the day of the transfer, you have the option to pay either by card or in cash.',
    },
  ];
  return (
    <S.Faq>
      <div>
        <h3>
          Discover insights from our customers Frequently Asked Questions.
        </h3>
        <p>
          Explore our <Link to='/faq'>FAQ</Link> page for answers to common
          questions and make the most of your experience with us. In case you
          don't find your question, feel free to contact us directly.
        </p>
      </div>
      <div>
        {faq?.map((el, e) => (
          <Box answer={el?.answ} question={el?.question} />
        ))}
      </div>
    </S.Faq>
  );
};

export default Faq;
