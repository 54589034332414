import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setAlert } from '../../slices/alertSlice';

import * as S from './styles';

const Alert = () => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.alert.message);
  const style = useSelector((state) => state.alert.style);

  useEffect(() => {
    message && hideAlertTimeout();
  }, [message]);

  const hideAlertTimeout = () => {
    setTimeout(() => {
      dispatch(setAlert({ message: '', style: '' }));
    }, 2500);
  };

  const iconMapping = {
    success: 'check',
    info: 'world',
    warning: 'warning',
  };
  return (
    message && (
      <S.Alert alertStyle={style}>
        {style === 'success' ? (
          <svg
            width='64px'
            height='64px'
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            fill='#000000'>
            <g id='SVGRepo_bgCarrier' strokeWidth={0} />
            <g
              id='SVGRepo_tracerCarrier'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <g id='SVGRepo_iconCarrier'>
              {' '}
              <defs>
                {' '}
                <style
                  dangerouslySetInnerHTML={{
                    __html:
                      '.cls-1{fill:none;stroke:#008347;stroke-linecap:round;stroke-linejoin:round;stroke-width:20px;}',
                  }}
                />{' '}
              </defs>{' '}
              <g data-name='Layer 2' id='Layer_2'>
                {' '}
                <g
                  data-name='E408, Success, Media, media player, multimedia'
                  id='E408_Success_Media_media_player_multimedia'>
                  {' '}
                  <circle className='cls-1' cx={256} cy={256} r={246} />{' '}
                  <polyline
                    className='cls-1'
                    points='115.54 268.77 200.67 353.9 396.46 158.1'
                  />{' '}
                </g>{' '}
              </g>{' '}
            </g>
          </svg>
        ) : (
          <svg
            width='64px'
            height='20px'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <g id='SVGRepo_bgCarrier' strokeWidth={0} />
            <g
              id='SVGRepo_tracerCarrier'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <g id='SVGRepo_iconCarrier'>
              <path
                d='M7.493 0.015 C 7.442 0.021,7.268 0.039,7.107 0.055 C 5.234 0.242,3.347 1.208,2.071 2.634 C 0.660 4.211,-0.057 6.168,0.009 8.253 C 0.124 11.854,2.599 14.903,6.110 15.771 C 8.169 16.280,10.433 15.917,12.227 14.791 C 14.017 13.666,15.270 11.933,15.771 9.887 C 15.943 9.186,15.983 8.829,15.983 8.000 C 15.983 7.171,15.943 6.814,15.771 6.113 C 14.979 2.878,12.315 0.498,9.000 0.064 C 8.716 0.027,7.683 -0.006,7.493 0.015 M8.853 1.563 C 9.967 1.707,11.010 2.136,11.944 2.834 C 12.273 3.080,12.920 3.727,13.166 4.056 C 13.727 4.807,14.142 5.690,14.330 6.535 C 14.544 7.500,14.544 8.500,14.330 9.465 C 13.916 11.326,12.605 12.978,10.867 13.828 C 10.239 14.135,9.591 14.336,8.880 14.444 C 8.456 14.509,7.544 14.509,7.120 14.444 C 5.172 14.148,3.528 13.085,2.493 11.451 C 2.279 11.114,1.999 10.526,1.859 10.119 C 1.618 9.422,1.514 8.781,1.514 8.000 C 1.514 6.961,1.715 6.075,2.160 5.160 C 2.500 4.462,2.846 3.980,3.413 3.413 C 3.980 2.846,4.462 2.500,5.160 2.160 C 6.313 1.599,7.567 1.397,8.853 1.563 M7.706 4.290 C 7.482 4.363,7.355 4.491,7.293 4.705 C 7.257 4.827,7.253 5.106,7.259 6.816 C 7.267 8.786,7.267 8.787,7.325 8.896 C 7.398 9.033,7.538 9.157,7.671 9.204 C 7.803 9.250,8.197 9.250,8.329 9.204 C 8.462 9.157,8.602 9.033,8.675 8.896 C 8.733 8.787,8.733 8.786,8.741 6.816 C 8.749 4.664,8.749 4.662,8.596 4.481 C 8.472 4.333,8.339 4.284,8.040 4.276 C 7.893 4.272,7.743 4.278,7.706 4.290 M7.786 10.530 C 7.597 10.592,7.410 10.753,7.319 10.932 C 7.249 11.072,7.237 11.325,7.294 11.495 C 7.388 11.780,7.697 12.000,8.000 12.000 C 8.303 12.000,8.612 11.780,8.706 11.495 C 8.763 11.325,8.751 11.072,8.681 10.932 C 8.616 10.804,8.460 10.646,8.333 10.580 C 8.217 10.520,7.904 10.491,7.786 10.530 '
                stroke='none'
                fillRule='evenodd'
                fill='#cf2d2d'
              />
            </g>
          </svg>
        )}
        {message}
      </S.Alert>
    )
  );
};

export default Alert;
