import Alert from './components/Alert';
import './App.css';
import Routes from './Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './TopScroll';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './slices/index';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setPriceAdjust } from './slices/paramsSlice';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

function App() {
  return (
    <PersistGate loading={null} persistor={persistor}>
      <PayPalScriptProvider
        options={{
          'client-id': `${process.env.REACT_APP_PAYPAL_PUBLIC}`,
          currency: 'EUR',
        }}>
        <Router>
          <ScrollToTop />
          <Alert />
          <Routes />
        </Router>
      </PayPalScriptProvider>
    </PersistGate>
  );
}

export default App;
