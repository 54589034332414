import { useEffect, useState } from 'react';
import * as S from './styles';
import axios from 'axios';

const FixedPrice = () => {
  const [stMoritzBusiness, setStMoritzBusiness] = useState();
  const [stMoritzVan, setStMoritzVan] = useState();
  const [stMoritzSClass, setStMoritzSClass] = useState();

  const [cerviniaBusiness, setCerviniaBusiness] = useState();
  const [cerviniaVan, setCerviniaVan] = useState();
  const [cerviniaSClass, setCerviniaSClass] = useState();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/get_values?dbName=moritz`)
      .then((res) => {
        setStMoritzBusiness(res?.data?.business);
        setStMoritzVan(res?.data?.van);
        setStMoritzSClass(res?.data?.sclass);
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/get_values?dbName=cervinia`)
      .then((res) => {
        setCerviniaBusiness(res?.data?.business);
        setCerviniaVan(res?.data?.van);
        setCerviniaSClass(res?.data?.sclass);
      });
  }, []);

  const submitInfo = (db, row, number) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/update_values`, {
        dbName: db,
        column: row,
        value: parseInt(number),
      })
      .then((res) => console.log(res?.data))
      .catch((err) => console.log(err));
  };

  return (
    <S.FixedPrice>
      <h2>Fixed Price for St Moritz</h2>
      <div>
        <div>
          <p>Business</p>
          <input
            value={stMoritzBusiness}
            onChange={(e) =>
              setStMoritzBusiness(e.target.value.replace(/[^0-9]/g, ''))
            }
          />
          <button
            onClick={() => submitInfo('moritz', 'business', stMoritzBusiness)}>
            Apply
          </button>
        </div>
        <div>
          <p>VAN</p>
          <input
            value={stMoritzVan}
            onChange={(e) =>
              setStMoritzVan(e.target.value.replace(/[^0-9]/g, ''))
            }
          />
          <button onClick={() => submitInfo('moritz', 'van', stMoritzVan)}>
            Apply
          </button>
        </div>
        <div>
          <p>S-CLASS</p>
          <input
            value={stMoritzSClass}
            onChange={(e) =>
              setStMoritzSClass(e.target.value.replace(/[^0-9]/g, ''))
            }
          />
          <button
            onClick={() => submitInfo('moritz', 'sclass', stMoritzSClass)}>
            Apply
          </button>
        </div>
      </div>

      <h2 className='last'>Fixed Price for Cervinia</h2>
      <div>
        <div>
          <p>Business</p>
          <input
            value={cerviniaBusiness}
            onChange={(e) =>
              setCerviniaBusiness(e.target.value.replace(/[^0-9]/g, ''))
            }
          />
          <button
            onClick={() =>
              submitInfo('cervinia', 'business', cerviniaBusiness)
            }>
            Apply
          </button>
        </div>
        <div>
          <p>VAN</p>
          <input
            value={cerviniaVan}
            onChange={(e) =>
              setCerviniaVan(e.target.value.replace(/[^0-9]/g, ''))
            }
          />
          <button onClick={() => submitInfo('cervinia', 'van', cerviniaVan)}>
            Apply
          </button>
        </div>
        <div>
          <p>S-CLASS</p>
          <input
            value={cerviniaSClass}
            onChange={(e) =>
              setCerviniaSClass(e.target.value.replace(/[^0-9]/g, ''))
            }
          />
          <button
            onClick={() => submitInfo('cervinia', 'sclass', cerviniaSClass)}>
            Apply
          </button>
        </div>
      </div>
    </S.FixedPrice>
  );
};

export default FixedPrice;
