import { createSlice } from '@reduxjs/toolkit';

const paramsSlice = createSlice({
  name: 'params',
  initialState: {
    data: {},
    loggedIn: false,
    priceAdjust: 0,
    isByHour: false,
  },
  reducers: {
    setData: (state, action) => {
      state.data = { ...action.payload };
    },
    setPriceAdjust: (state, action) => {
      state.priceAdjust = action.payload;
    },
    setLogin: (state, action) => {
      state.loggedIn = true;
    },
    setIsByHour: (state, action) => {
      state.isByHour = action.payload;
    },
    setNullData: (state, action) => {
      state.data = {};
    },
  },
});
export const { setNullData, setData, setLogin, setPriceAdjust, setIsByHour } =
  paramsSlice.actions;

export default paramsSlice.reducer;
