import { Link } from 'react-router-dom';
import * as S from './styles';

const OurServices = () => {
  return (
    <S.Services>
      <h2>Our Services</h2>
      <div>
        <div>
          <img src='/img/airport.png' alt='Airport Transfer' />
          <h3>Airport Transfers</h3>
          <p>
            Private Airport Transfers drive you and your passengers direct to
            your accommodation. Ideal for private addresses.
          </p>
        </div>
        <div>
          <img src='/img/hotel.png' alt='Airport Transfer' />
          <h3>Hotel Transfers</h3>
          <p>
            For your comfort and peace of mind our hotel transfer offers a wide
            choice of luxurious hotel transfers with professional and efficient
            chauffeurs from/to any location of your choice.
          </p>
        </div>
        <div>
          <img src='/img/mountain.png' alt='Airport Transfer' />
          <h3>Mountain / Ski Transfers</h3>
          <p>
            We provide a transfer shuttle service from Airport to resorts across
            the Italian Alps, Swiss and French Ski Resorts every day of the
            year.
          </p>
        </div>
      </div>
    </S.Services>
  );
};

export default OurServices;
