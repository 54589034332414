import styled from 'styled-components';

export const CustomHero = styled.div`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: 860px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding-top: 24px;
  border-top: 1px solid #4c4c4c;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 2;
  @media (max-width: 560px) {
    flex-direction: column;
    position: relative;
    margin-top: 64px;
    border-top: 0;
    max-width: 260px;
    align-items: flex-start;
    margin-left: auto;
    margin-rigth: auto;
    left: unset;
    transform: none;
  }
  h3 {
    font-size: 15px;
    color: #fff;
    z-index: 2;
    position: relative;
  }
  & > div {
    display: flex;
    align-items: center;
    gap: 8px;
    img {
      max-width: 30px;
      position: relative;
      z-index: 2;
    }
  }
  & > img {
    position: absolute;
    width: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 0;
    height: 68px;
    left: 0;
    top: 0;
    opacity: 1;
    border-radius: 0 0 35px 35px;
  }
`;
