import styled from 'styled-components';

export const Admin = styled.div`
  h1 {
    text-align: center;
    margin-top: 32px;
    font-family: 'Inter', sans-serif;
  }
  .admin_login {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 64px;
    gap: 8px;
    max-width: 250px;
    margin: 64px auto;
    input {
      width: 250px;
      padding: 8px 16px;
      border-radius: 6px;
      border: none;
      outline: none;
      background: #edeef3;
    }
    button {
      width: 250px;
      background: #94705e;
      color: #fff;
      border-radius: 6px;
      border: none;
      outline: none;
      padding: 8px 16px;
      cursor: pointer;
    }
  }
`;

export const AdminDashboard = styled.div`
  max-width: 1170px;
  margin: 12px auto;
  @media (max-width: 1220px) {
    padding: 0 15px;
  }
  .row_completed {
    max-width: 30px;
    position: relative;
    top: 23px;
    transform: rotate(90deg);
    &.active {
      transform: rotate(-90deg);
    }
  }
  .pricing_adjust {
    max-width: 360px;
    margin: 32px auto;
    h3 {
      text-align: center;
      margin: 0 auto;
      width: fit-content;
      margin-bottom: 8px;
    }
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      p {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border: 1px solid #3290ec;
        color: #3290ec;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        &.active {
          color: #fff;
          background: #3290ec;
        }
      }
    }
  }
  .tabs {
    max-width: fit-content;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #936f5e;
    border-radius: 6px;
    gap: 3px;
    margin-bottom: 32px;
    & > div {
      padding: 10px 10px;
      border-radius: 4px;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      &.active {
        background: #936f5e;
        color: #fff;
      }
    }
  }
  .upcoming {
    h2 {
      font-size: 32px;
      margin-bottom: 8px;
    }
    & > p {
      margin-bottom: 32px;
      color: rgb(100, 102, 107);
      font-weight: 300;
      span {
        color: rgb(62, 57, 53) !important;
        font-weight: 400;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    & > div {
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border: 1px solid #ccc;
      & > div {
        width: 100%;
        background: #f0f1f6;
        padding: 12px 12px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        gap: 10px;
        flex-wrap: wrap;
        position: relative;
        padding-right: 32px;
        svg {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          right: 12px;
          max-width: 30px;
          cursor: pointer;
        }
        h3 {
          padding: 6px;
          border-radius: 8px;
          background: red;
          background: #3290ec;
          color: #fff;
          font-size: 16px;
          &.with_return {
            background: #665cff;
          }
        }
        .span {
          width: 2px;
          height: 20px;
          background: #665cff;
          border-radius: 2px;
          @media (max-width: 768px) {
            display: none;
          }
        }
        br {
          @media (max-width: 768px) {
            display: none;
          }
        }
        p {
          font-size: 14px;
          font-weight: 300;
          @media (max-width: 768px) {
            width: 100%;
          }
          span {
            font-weight: 400;
          }
        }
        &:not(:last-child) {
          border-bottom: 1px solid #ccc;
        }
      }
    }
  }
`;

export const CustomRequest = styled.div`
  h2 {
    font-size: 32px;
    margin-bottom: 8px;
  }
  & > p {
    margin-bottom: 32px;
    color: rgb(100, 102, 107);
    font-weight: 300;
    span {
      color: rgb(62, 57, 53) !important;
      font-weight: 400;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  & > div {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: 1px solid #ccc;
    & > div {
      width: 100%;
      background: #f0f1f6;
      padding: 12px 30px 22px 12px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      gap: 10px;
      flex-wrap: wrap;
      position: relative;
      padding-right: 32px;
      .message {
        width: 100%;
      }
      & > div {
        position: absolute;
        bottom: 10px;
        right: 10px;
        p {
          color: red;
        }
      }
      h3 {
        padding: 6px;
        border-radius: 8px;
        background: red;
        background: #3290ec;
        color: #fff;
        font-size: 16px;
        &.with_return {
          background: #665cff;
        }
      }
      .span {
        width: 2px;
        height: 20px;
        background: #665cff;
        border-radius: 2px;
        @media (max-width: 768px) {
          display: none;
        }
      }
      p {
        font-size: 14px;
        font-weight: 300;
        span {
          font-weight: 400;
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid #ccc;
      }
    }
  }
`;

export const FixedPrice = styled.div`
  h2 {
    margin-bottom: 16px;
    &.last {
      margin-top: 32px;
    }
  }
  & > div > div {
    margin-bottom: 12px;
    p {
      font-size: 14px;
      margin-bottom: 6px;
      font-weight: 500;
    }
    input {
      padding: 10px 15px 10px 15px;
      background: #eff2f6;
      border: 1px solid #f1f2f7;
      border-radius: 6px;
      transition: 0.3s ease-in-out;
      resize: none;
      font-family: inherit;
      font-size: 16px;
      &:focus {
        background-color: var(--bs-body-bg);
        border-color: #86b7fe;
        box-shadow: 0 0 0 0.25rem #0d6efd40;
        color: var(--bs-body-color);
        outline: 0;
      }
    }
    button {
      padding: 13px 16px;
      border-radius: 8px;
      color: #fff;
      background: rgb(2, 0, 36);
      background: rgba(97, 71, 58, 1);
      margin-left: 8px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      transition: 0.3s ease-in-out;
      font-size: 14px;
      border: none;
      outline: none;
      cursor: pointer;
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }
`;
