import { useEffect, useState } from 'react';
import * as S from './styles';
import { setAlert } from '../../slices/alertSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setLogin } from '../../slices/paramsSlice';
import axios from 'axios';
import moment from 'moment/moment';
import Row from './Row';
import CustomRequests from './custom';
import FixedPrice from './FixedPrice';

const Admin = () => {
  const [password, setPassword] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const loggedIn = useSelector((state) => state.params.loggedIn);
  const [transfers, setTransfers] = useState([]);
  const [customRequests, setCustomRequests] = useState([]);
  const [sorting, setSorting] = useState('created');
  const [selectedTab, setSelectedTab] = useState('transfers');
  const [pricePercent, setPricePercent] = useState();
  const [showCompletedTransfers, setShowCompletedTransfers] = useState(false);

  const dispatch = useDispatch();
  const submit = () => {
    if (password === 'AdminAdmin123123') {
      setIsAdmin(true);
      dispatch(setLogin());
    } else {
      dispatch(setAlert({ message: 'Incorrect Password' }));
    }
  };

  useEffect(() => {
    if (loggedIn) {
      setIsAdmin(true);
    }
  }, [loggedIn]);

  useEffect(() => {
    if (isAdmin) {
      axios.get(`${process.env.REACT_APP_API_URL}/transfers`).then((res) => {
        setTransfers([...res?.data]);
      });
      axios
        .get(`${process.env.REACT_APP_API_URL}/custom_request`)
        .then((res) => {
          setCustomRequests([...res?.data]);
        });
      axios
        .get(`${process.env.REACT_APP_API_URL}/price-percent`)
        .then((res) => {
          setPricePercent(res?.data?.price_percent);
        });
    }
  }, [isAdmin]);

  const updatePriceAdjust = (percent) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/update-price-percent`, {
        newPricePercent: percent,
      })
      .then((res) => {
        dispatch(
          setAlert({
            message: 'Price was adjusted successfully!',
            style: 'success',
          })
        );
      });
  };

  const deleteCustomRequest = (email, phone) => {
    console.log(email, phone);
    axios
      .post(`${process.env.REACT_APP_API_URL}/delete_request`, {
        email: email,
        phone: phone,
      })
      .then((res) => {
        dispatch(
          setAlert({
            message: 'Custom Request Deleted',
            style: 'success',
          })
        );
        axios
          .get(`${process.env.REACT_APP_API_URL}/custom_request`)
          .then((res) => {
            setCustomRequests([...res?.data]);
          });
      })
      .catch((err) =>
        dispatch(
          setAlert({
            message: 'Ups! Something went wrong!',
          })
        )
      );
  };

  return (
    <S.Admin>
      <h1>Admin Dashboard</h1>
      {!isAdmin ? (
        <div className='admin_login'>
          <p>Password*</p>
          <input
            placeholder='Admin Password'
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            type='password'
          />
          <button onClick={() => submit()}>Submit</button>
        </div>
      ) : (
        <S.AdminDashboard>
          <div className='tabs'>
            <div
              onClick={() => setSelectedTab('transfers')}
              className={selectedTab === 'transfers' ? 'active' : ''}>
              Transfers
            </div>
            <div
              onClick={() => setSelectedTab('custom_request')}
              className={selectedTab === 'custom_request' ? 'active' : ''}>
              Custom Requests
            </div>
            <div
              onClick={() => setSelectedTab('by_hour')}
              className={selectedTab === 'by_hour' ? 'active' : ''}>
              By Hour
            </div>
            <div
              onClick={() => setSelectedTab('fixed_price')}
              className={selectedTab === 'fixed_price' ? 'active' : ''}>
              Fixed Prices
            </div>
          </div>

          {selectedTab === 'transfers' ? (
            <>
              <div className='pricing_adjust'>
                <h3>Current Price Adjustment: {pricePercent}%</h3>
                {selectedTab !== 'fixed_price' && (
                  <>
                    <div>
                      <p
                        onClick={() => {
                          setPricePercent(0);
                          updatePriceAdjust(0);
                        }}
                        className={pricePercent === 0 ? 'active' : ''}>
                        0%
                      </p>
                      <p
                        onClick={() => {
                          setPricePercent(-5);
                          updatePriceAdjust(-5);
                        }}
                        className={pricePercent === -5 ? 'active' : ''}>
                        -5%
                      </p>
                      <p
                        onClick={() => {
                          setPricePercent(-10);
                          updatePriceAdjust(-10);
                        }}
                        className={pricePercent === -10 ? 'active' : ''}>
                        -10%
                      </p>
                      <p
                        onClick={() => {
                          setPricePercent(-15);
                          updatePriceAdjust(-15);
                        }}
                        className={pricePercent === -15 ? 'active' : ''}>
                        -15%
                      </p>
                      <p
                        onClick={() => {
                          setPricePercent(-20);
                          updatePriceAdjust(-20);
                        }}
                        className={pricePercent === -20 ? 'active' : ''}>
                        -20%
                      </p>
                      <p
                        onClick={() => {
                          setPricePercent(-25);
                          updatePriceAdjust(-25);
                        }}
                        className={pricePercent === -25 ? 'active' : ''}>
                        -25%
                      </p>
                    </div>
                    <div>
                      <p
                        onClick={() => {
                          setPricePercent(5);
                          updatePriceAdjust(5);
                        }}
                        className={pricePercent === 5 ? 'active' : ''}>
                        +5%
                      </p>
                      <p
                        onClick={() => {
                          setPricePercent(10);
                          updatePriceAdjust(10);
                        }}
                        className={pricePercent === 10 ? 'active' : ''}>
                        +10%
                      </p>
                      <p
                        onClick={() => {
                          setPricePercent(15);
                          updatePriceAdjust(15);
                        }}
                        className={pricePercent === 15 ? 'active' : ''}>
                        +15%
                      </p>
                      <p
                        onClick={() => {
                          setPricePercent(20);
                          updatePriceAdjust(20);
                        }}
                        className={pricePercent === 20 ? 'active' : ''}>
                        +20%
                      </p>
                      <p
                        onClick={() => {
                          setPricePercent(25);
                          updatePriceAdjust(25);
                        }}
                        className={pricePercent === 25 ? 'active' : ''}>
                        +25%
                      </p>
                    </div>
                  </>
                )}
              </div>
              <div className='upcoming'>
                <h2>Upcoming Transfers</h2>
                <p>
                  Sort by{' '}
                  <span onClick={() => setSorting('created')}>
                    Date Created
                  </span>{' '}
                  or{' '}
                  <span onClick={() => setSorting('transfer_day')}>
                    Transfer Day
                  </span>
                </p>
                <div>
                  {transfers
                    .filter((elem) => {
                      const today = new Date();
                      today.setHours(0, 0, 0, 0);
                      const itemDate = new Date(elem.date_to);
                      const itemDateReturn = new Date(elem.date_return);
                      if (elem?.date_return) {
                        return itemDate >= today && itemDateReturn >= today;
                      } else {
                        return itemDate >= today;
                      }
                    })
                    .filter((elem) => elem?.type_transfer !== 'Hour')
                    .sort((a, b) => {
                      if (sorting === 'created') {
                        const itemDate = new Date(a.date_created);
                        const itemDateB = new Date(b.date_created);
                        return itemDateB - itemDate;
                      } else if (sorting === 'transfer_day') {
                        const itemDate = new Date(a.date_to);
                        const itemDateB = new Date(b.date_to);
                        return itemDate - itemDateB;
                      }
                    })
                    .map((elem, i) => (
                      <Row elem={elem} />
                    ))}
                </div>
              </div>
              <div className='upcoming'>
                <h2 style={{ marginTop: '48px', marginBottom: '24px' }}>
                  Completed Transfers{' '}
                  <svg
                    width='64px'
                    height='64px'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className={
                      showCompletedTransfers
                        ? 'row_completed active'
                        : 'row_completed'
                    }
                    onClick={() =>
                      setShowCompletedTransfers(!showCompletedTransfers)
                    }>
                    <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                    <g
                      id='SVGRepo_tracerCarrier'
                      stroke-linecap='round'
                      stroke-linejoin='round'></g>
                    <g id='SVGRepo_iconCarrier'>
                      {' '}
                      <path
                        d='M5 12H19M19 12L13 6M19 12L13 18'
                        stroke='#665cff'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'></path>{' '}
                    </g>
                  </svg>
                </h2>
                {showCompletedTransfers && (
                  <div>
                    {transfers
                      .filter((elem) => {
                        const today = new Date();
                        today.setHours(0, 0, 0, 0);
                        const itemDate = new Date(elem?.date_to);
                        const itemDateReturn = new Date(elem?.date_return);
                        if (elem?.date_return) {
                          return itemDate < today && itemDateReturn < today;
                        } else {
                          return itemDate < today;
                        }
                        // Compare dates
                      })
                      .map((elem, i) => (
                        <Row elem={elem} />
                      ))}
                  </div>
                )}
              </div>
            </>
          ) : selectedTab === 'custom_request' ? (
            <CustomRequests
              customRequests={customRequests}
              deleteCustomRequest={deleteCustomRequest}
            />
          ) : selectedTab === 'by_hour' ? (
            <>
              <div className='upcoming'>
                <h2>Upcoming Transfers</h2>
                <p>
                  Sort by{' '}
                  <span onClick={() => setSorting('created')}>
                    Date Created
                  </span>{' '}
                  or{' '}
                  <span onClick={() => setSorting('transfer_day')}>
                    Transfer Day
                  </span>
                </p>
                <div>
                  {transfers
                    .filter((elem) => {
                      const today = new Date();
                      today.setHours(0, 0, 0, 0);
                      const itemDate = new Date(elem.date_to);
                      const itemDateReturn = new Date(elem.date_return);
                      if (elem?.date_return) {
                        return itemDate >= today && itemDateReturn >= today;
                      } else {
                        return itemDate >= today;
                      }
                    })
                    .filter((elem) => elem?.type_transfer === 'Hour')
                    .sort((a, b) => {
                      if (sorting === 'created') {
                        const itemDate = new Date(a.date_created);
                        const itemDateB = new Date(b.date_created);
                        return itemDateB - itemDate;
                      } else if (sorting === 'transfer_day') {
                        const itemDate = new Date(a.date_to);
                        const itemDateB = new Date(b.date_to);
                        return itemDate - itemDateB;
                      }
                    })
                    .map((elem, i) => (
                      <Row elem={elem} by_hour />
                    ))}
                </div>
              </div>
              <div className='upcoming'>
                <h2 style={{ marginTop: '48px', marginBottom: '24px' }}>
                  Completed Transfers{' '}
                  <svg
                    width='64px'
                    height='64px'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className={
                      showCompletedTransfers
                        ? 'row_completed active'
                        : 'row_completed'
                    }
                    onClick={() =>
                      setShowCompletedTransfers(!showCompletedTransfers)
                    }>
                    <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                    <g
                      id='SVGRepo_tracerCarrier'
                      stroke-linecap='round'
                      stroke-linejoin='round'></g>
                    <g id='SVGRepo_iconCarrier'>
                      {' '}
                      <path
                        d='M5 12H19M19 12L13 6M19 12L13 18'
                        stroke='#665cff'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'></path>{' '}
                    </g>
                  </svg>
                </h2>
                {showCompletedTransfers && (
                  <div>
                    {transfers
                      .filter((elem) => {
                        const today = new Date();
                        today.setHours(0, 0, 0, 0);
                        const itemDate = new Date(elem?.date_to);
                        const itemDateReturn = new Date(elem?.date_return);
                        if (elem?.date_return) {
                          return itemDate < today && itemDateReturn < today;
                        } else {
                          return itemDate < today;
                        }
                        // Compare dates
                      })
                      .map((elem, i) => (
                        <Row elem={elem} />
                      ))}
                  </div>
                )}
              </div>
            </>
          ) : (
            selectedTab === 'fixed_price' && <FixedPrice />
          )}
        </S.AdminDashboard>
      )}
    </S.Admin>
  );
};

export default Admin;
