import * as S from './styles';

const Steps = () => {
  return (
    <S.Steps>
      <div>
        <h2>Business Rides at Their Best</h2>
        <div>
          <div>
            <img src='/img/line.png' alt='line' className='line' />
            <div>
              <img src='/img/click.png' alt='Book Online' />
            </div>
            <h4>Book your Ride</h4>
            <p>
              Receive instant confirmation and adjust your plans without worries
              in just a few clicks.
            </p>
          </div>
          <div>
            <img src='/img/line.png' alt='line' className='line' />
            <div>
              <img src='/img/travelman.png' alt='Book Online' />
            </div>
            <h4>Meeting Your Personal Driver</h4>
            <p>
              Upon your arrival, you will be greeted by our driver and escorted
              to your vehicle.
            </p>
          </div>
          <div>
            <div>
              <img
                src='/img/destination.png'
                alt='Book Online'
                className='lower'
              />
            </div>
            <h4>Reaching Your Destination</h4>
            <p>
              Your driver escorts you to your chosen destination, making sure
              all professional standards are in place.
            </p>
          </div>
        </div>
      </div>
    </S.Steps>
  );
};

export default Steps;
