import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import * as S from './styles';
import { useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../slices/alertSlice';
import axios from 'axios';
const Contact = () => {
  const validateEmail = (val) => {
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(val);
  };

  const [data, setData] = useState({});
  const dispatch = useDispatch();

  const submit = () => {
    if (data?.first_name && data?.last_name) {
      if (data?.phone) {
        if (data?.email) {
          if (validateEmail(data?.email)) {
            if (data?.subject) {
              if (data?.message) {
                axios
                  .post('https://submit-form.com/DuE08eSJc', {
                    'First Name': data?.first_name,
                    'Last Name': data?.last_name,
                    Email: data?.email,
                    'Phone Number': data?.phone,
                    Subject: data?.subject,
                    Message: data?.message,
                  })
                  .then((res) => {
                    dispatch(
                      setAlert({
                        message: 'Message was sent successfully!',
                        style: 'success',
                      })
                    );
                    setData({
                      first_name: '',
                      last_name: '',
                      email: '',
                      subject: '',
                      message: '',
                    });
                  })
                  .catch((err) => console.log(err));
              } else {
                dispatch(setAlert({ message: 'Please Provide a Message' }));
              }
            } else {
              dispatch(setAlert({ message: 'Please Provide a Subject' }));
            }
          } else {
            dispatch(
              setAlert({ message: 'Please Provide a Valid Email Address' })
            );
          }
        } else {
          dispatch(setAlert({ message: 'Please Provide an Email Address' }));
        }
      } else {
        dispatch(setAlert({ message: 'Please Provide a Valid Phone Number' }));
      }
    } else {
      dispatch(setAlert({ message: 'Please provide all required fields' }));
    }
  };
  return (
    <S.Contact>
      <h1>Get in Touch</h1>
      <p>
        Have a question or need assistance with your Business Transfers? We're
        here to help! Fill out the form below, and one of our dedicated agents
        will reach out to you as soon as possible. Your inquiries are important
        to us, and we're committed to providing you with prompt and personalized
        support. We look forward to connecting with you!
      </p>
      <div>
        <div className='main_block'>
          <div className='row'>
            <div className='input_custom'>
              <input
                type='text'
                placeholder='John'
                value={data?.first_name}
                onChange={(e) =>
                  setData({ ...data, first_name: e.target.value })
                }
              />
              <p>First Name</p>
            </div>
            <div className='input_custom'>
              <input
                type='text'
                placeholder='Doe'
                value={data?.last_name}
                onChange={(e) =>
                  setData({ ...data, last_name: e.target.value })
                }
              />
              <p>Last Name</p>
            </div>
          </div>
          <div className='input_custom phone'>
            <PhoneInput
              placeholder='Enter phone number'
              value={data?.phone}
              defaultCountry='IT'
              onChange={(e) => {
                setData({
                  ...data,
                  phone: e,
                });
              }}
            />
            <p>Phone Number</p>
          </div>
          <div className='input_custom'>
            <input
              type='text'
              placeholder='john.doe@gmail.com'
              value={data?.email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
            <p>Email Address</p>
          </div>
          <div className='input_custom'>
            <input
              type='text'
              placeholder='Subject'
              value={data?.subject}
              onChange={(e) => setData({ ...data, subject: e.target.value })}
            />
            <p>Subject</p>
          </div>
          <div className='input_custom'>
            <textarea
              type='text'
              placeholder='Type here...'
              value={data?.message}
              onChange={(e) => setData({ ...data, message: e.target.value })}
            />
            <p>Message</p>
          </div>
          <button onClick={submit}>Send Message</button>
          <p className='italic'>
            Our agents will be in contact with you tipically within a couple of
            hours.
          </p>
        </div>
        <img src='/img/contact.png' alt='Contact us' />
      </div>
    </S.Contact>
  );
};

export default Contact;
