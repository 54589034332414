import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import * as S from './styles';
import Box from '../Faq/Box';

const FaqComponent = () => {
  const faq = [
    {
      question: 'Can I cancel my ride?',
      answ: 'If you have already booked a ride and wish to cancel your transfer, you must do so at least 24 hours before the scheduled transfer time. Failure to cancel within this timeframe will result in the inability to cancel the ride, and the remaining amount will be charged to the card used for the initial payment.',
    },
    {
      question: "What if I don't show up?",
      answ: "In the event that you do not show up for your scheduled transfer without contacting us, you will be charged the remaining amount on the card you provided during the booking process. It's important to inform us of any changes or cancellations to avoid these charges.",
    },
    {
      question: 'What if my flight is delayed?',
      answ: 'No need to worry! Our professional drivers continuously monitor flight dashboards, ensuring they are informed of any delays. Rest assured, they will wait for you accordingly, so you can relax knowing your transportation needs are being taken care of, even in the event of a delay.',
    },
    {
      question: 'How do I recognize my driver?',
      answ: "Our drivers are easily identifiable, as they are always well-dressed and positioned at the exit immediately after luggage pickup, holding a sign with your name clearly written on it. You can trust that you'll be able to spot them effortlessly. However, if you encounter any difficulties locating your driver, please don't hesitate to contact us using the phone number provided in your confirmation email. We're here to assist you.",
    },
    {
      question: 'What are the accepted payment methods?',
      answ: 'The advance payment made during the booking process can be settled using a debit or credit card, including Visa, MasterCard, AMEX, and various others. For the remaining balance payable on the day of the transfer, you have the option to pay either by card or in cash.',
    },
    {
      question: 'Do you provide Ski/Mountain Transfers?',
      answ: 'Yes, of course! We offer ski and mountain transfers to various destinations, including ski resorts in Italy, Switzerland, and France.',
    },
    {
      question: 'Do you have services per hour?',
      answ: 'For services per hour, you need to send us a custom request on our homepage. We will promptly respond to accommodate all your needs and discuss pricing details.',
    },
    {
      question: 'Are animals allowed in the car?',
      answ: 'Absolutely! We allow up to 2 animals in the car, provided they are in respective carriers for their safety and comfort.',
    },
    {
      question: 'Can I book a baby seat for the transfer?',
      answ: 'Certainly! You can easily book a baby seat for your transfer by simply selecting the option while making your booking.',
    },
    {
      question: 'Can I select the car when booking the transfer?',
      answ: 'Certainly! When booking your transfer, after selecting the date and destinations, you will be presented with various car options. You can then choose your preferred car from the available options, each displaying its respective price.',
    },
    {
      question: 'How many stops can I make until the final destination?',
      answ: 'You are allowed to make up to 2 stops during your transfer. However, please note that each stop will incur an additional cost of 20 euros.',
    },
    {
      question: 'What is the minimum price for a ride?',
      answ: 'The minimum price for a ride is 70 euros, although this may vary depending on the specific destinations involved in your transfer.',
    },
  ];
  return (
    <S.FaqComponent>
      <h1>Frequently Asked Questions</h1>
      <p>
        Here, you'll discover answers to the most commonly asked questions by
        our valued customers regarding our business. If you don't find the
        answer you're looking for, don't hesitate to reach out to us through our{' '}
        <Link to='/contact-us'>contact page</Link>. Your satisfaction is our
        priority, and we're here to assist you every step of the way.
      </p>
      <div className='column'>
        <div>
          {faq?.slice(0, 5).map((e, i) => (
            <>
              <Box question={e?.question} answer={e?.answ} />
            </>
          ))}
        </div>
        <div>
          {faq?.slice(5, 10).map((e, i) => (
            <>
              <Box question={e?.question} answer={e?.answ} />
            </>
          ))}
        </div>
      </div>
    </S.FaqComponent>
  );
};

export default FaqComponent;
