import styled, { css } from 'styled-components';

const helperSlidesInAnimation = css`
  @keyframes helper-slides-in {
    0% {
      top: -100px;
    }

    25% {
      top: 40px;
    }

    75% {
      top: 40px;
    }

    100% {
      top: -100px;
    }
  }

  -webkit-animation: helper-slides-in 2.5s 1; /* Safari 4+ */
  -moz-animation: helper-slides-in 2.5s 1; /* Fx 5+ */
  -o-animation: helper-slides-in 2.5s 1; /* Opera 12+ */
  animation: helper-slides-in 2.5s 1; /* IE 10+, Fx 29+ */
`;

const getAlertColor = ({ alertStyle }) => {
  if (alertStyle === 'success')
    return css`
      background: #e4f6ed;
      color: #008347;
      path {
        stroke: #008347 !important;
      }
    `;
  else if (alertStyle === 'info')
    return css`
      background: #e7eaf8;
      color: #2653c2;
      path {
        stroke: #2653c2 !important;
      }
    `;
  else if (alertStyle === 'warning')
    return css`
      background: #fff9c4;
      color: #f9a825;
      path {
        stroke: #f9a825 !important;
      }
    `;

  return css`
    background: #feccd1;
    color: #cf2d2d;
  `;
};

export const Alert = styled.div`
  ${helperSlidesInAnimation}
  display: flex;
  align-items: center;
  max-width: 350px;
  min-width: fit-content;
  padding: 12px 16px 12px 48px;
  border-radius: 4px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  position: fixed;
  top: -100px;
  left: 50%;
  transform: translate(-50%, 0);
  transition: top 0.2s, opacity 0.1s;
  z-index: 9999999999;
  font-size: 14px;
  svg {
    max-width: 20px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 15px;
    z-index: 2;
  }
  & > div {
    margin-right: 8px;
    width: 40px;
    height: 40px;
  }
  ${getAlertColor}
`;
