import styled from 'styled-components';

export const AdminTrip = styled.div`
  max-width: 1170px;
  margin: 12px auto;
  position: relative;
  @media (max-width: 1220px) {
    padding: 0 15px;
  }
  h1 {
    text-align: center;
    margin-top: 32px;
  }
  .download {
    position: absolute;
    right: 0;
    border: none;
    background: none;
    cursor: pointer;
    @media (max-width: 768px) {
      right: 15px;
      top: 10px;
    }
  }

  @media print {
    .hide-on-print {
      display: none;
    }
  }
  h4 {
    text-align: center;
    font-size: 32px;
    margin: 32px auto;
  }
  & > h3 {
    text-align: center;
  }
  & > div {
    display: flex;
    flex-direction: column;
    max-width: 1170px;
    margin: 12px auto;
    margin-top: 32px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: 1px solid #ccc;
    border-radius: 6px;
    overflow: hidden;
    & > div {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      padding: 14px;
      background: #f0f1f6;
      position: relative;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      &::after {
        position: absolute;
        left: 50%;
        content: '';
        width: 1px;
        height: calc(100% + 28px);
        background: #ccc;
        top: -14px;
        @media (max-width: 768px) {
          display: none;
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid #ccc;
      }
      p {
        width: 50%;
        @media (max-width: 768px) {
          width: 100%;
          &:first-of-type {
            font-weight: 600;
            margin-bottom: 4px;
          }
        }
        &:last-of-type {
          padding-left: 16px;
          @media (max-width: 768px) {
            padding-left: 0;
          }
        }
      }
    }
  }
`;
