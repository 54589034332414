import { useEffect, useState } from 'react';
import * as S from './styles';
import { useSelector } from 'react-redux';
import axios from 'axios';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Step5 = () => {
  useEffect(() => {
    postDataApi();
  }, []);

  const data = useSelector((state) => state.params.data);
  const history = useHistory();

  const [inProgress, setInProgress] = useState(false);

  const postDataApi = () => {
    setInProgress(true);
    const trip_id = Math.floor(100000 + Math.random() * 900000);
    axios
      .post(`${process.env.REACT_APP_API_URL}/transfers`, {
        confirmed_information: true,
        email: data?.email,
        first_name: data?.first_name,
        flight_number: data?.flight_number,
        from_full_address: data?.fromFull,
        from_full_address_return: data?.fromFullReturn,
        baby_seat: data?.isBabySeat,
        baby_seat_return: data?.isBabySeatReturn,
        extra_stop: data?.isExtraStop,
        extra_stop_return: data?.isExtraStopReturn,
        is_flight: data?.isFlight,
        is_other_services: data?.isOtherServices,
        is_other_services_return: data?.isOtherServicesReturn,
        last_name: data?.last_name,
        meet_name: data?.meet_name,
        notes_chauffeur: data?.notes_chauffeur,
        other_services_text: data?.otherServicesText,
        other_services_text_return: data?.otherServicesTextReturn,
        other_notes: data?.other_notes,
        persons: data?.persons,
        phone: data?.phone,
        price_to: data?.priceTo,
        price_return: data?.priceFrom,
        duration: data?.hour,
        price_total: data?.price,
        date_to: data?.with_return ? data?.range?.from : data?.range,
        date_return: data?.with_return ? data?.range?.to : null,
        type_transfer:
          data?.tab === 'with_return'
            ? 'With Return'
            : data?.tab === 'hour'
            ? 'Hour'
            : 'Single Direction',
        time: `${
          dayjs(data?.time).hour() < 10
            ? `0${dayjs(data?.time).hour()}`
            : dayjs(data?.time).hour()
        }:${
          dayjs(data?.time).minute() < 10
            ? `0${dayjs(data?.time).minute()}`
            : dayjs(data?.time).minute()
        }`,
        time_return: data?.with_return
          ? `${
              dayjs(data?.timeReturn).hour() < 10
                ? `0${dayjs(data?.timeReturn).hour()}`
                : dayjs(data?.timeReturn).hour()
            }:${
              dayjs(data?.timeReturn).minute() < 10
                ? `0${dayjs(data?.timeReturn).minute()}`
                : dayjs(data?.timeReturn).minute()
            }`
          : null,
        to_full_address: data?.toFull,
        to_full_address_return: data?.toFullReturn,
        car_type: data?.typeCar,
        car_type_return: data?.typeCarReturn,
        is_with_return: data?.with_return,
        deposit_percent: data?.deposit_percent,
        left_to_pay: data?.left_to_pay,
        date_created: new Date(),
        trip_id: trip_id,
      })
      .then((res) => {
        axios
          .post('https://submit-form.com/u7JwZUldQ', {
            'Booking ID': trip_id,
            Amount: `€${data?.price}`,
            Message: 'Please check the admin panel for more information',
          })
          .then((res) => {
            setInProgress(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  return (
    <S.Step5>
      <h2>
        <img src='/img/check_circle.svg' />
        Congratulations! Your booking is confirmed.
      </h2>
      <p>
        Your booking has been successfully processed. A confirmation email will
        be sent shortly, to the address indicated in the previous steps. Thank
        you for selecting our services—we are committed to delivering a
        professional and luxurious experience that will have you returning for
        more.
      </p>
      <img src='/img/checked.png' alt='Checked' />
      <button onClick={() => !inProgress && history.push('/')}>
        Back to Homepage
      </button>
    </S.Step5>
  );
};

export default Step5;
