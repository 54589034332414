import styled from 'styled-components';

export const Services = styled.div`
  max-width: 1170px;
  margin: 48px auto;
  @media (max-width: 1220px) {
    padding: 0 15px;
    margin-bottom: 24px;
  }

  h2 {
    text-align: center;
    font-size: 42px;
    margin-bottom: 32px;
    @media (max-width: 768px) {
      font-size: 32px;
      text-align: center;
    }
  }
  & > div {
    display: flex;
    align-items: flex-start;
    gap: 32px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    & > div {
      width: calc(33% - 18px);
      @media (max-width: 768px) {
        width: 100%;
      }
      img {
        width: 100%;
        height: 150px;
        border-radius: 16px;
        object-fit: cover;
        object-position: center;
        margin-bottom: 8px;
      }
      h3 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 8px;
      }
      p {
        font-size: 14px;
        margin-bottom: 8px;
      }
    }
  }
`;
