import styled from 'styled-components';

export const Terms = styled.div`
  --tab-gutter: 5px;
  --yocto: 1px;
  --zepto: 2px;
  --atto: 4px;
  --femto: 8px;
  --pico: 12px;
  --nano: 16px;
  --micro: 20px;
  --milli: 24px;
  --centi: 28px;
  --deci: 32px;
  --deka: 40px;
  --hecto: 48px;
  --kilo: 64px;
  --mega: 96px;
  --giga: 133px;
  --ultra: 172px;
  --tera: 180px;
  --formFieldPadding: var(--micro) var(--femto) var(--femto) var(--femto);
  --outline-size: 584px;
  --letter-spacing-extra-small: 0.1px;
  --letter-spacing-small: 0.15px;
  --letter-spacing-medium: 0.22px;
  min-height: calc(100vh - 481px);
  max-width: 1170px;
  margin: 80px auto 0;
  padding-top: 32px;
  @media (max-width: 1240px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  h1 {
    font-size: 42px !important;
    margin-bottom: 8px !important;
    color: #3e3935 !important;
    line-height: 54px !important;
    @media (max-width: 768px) {
      font-size: 28px !important;
      line-height: 35px !important;
      margin-bottom: 16px !important;
    }
  }
  a {
    text-decoration: underline !important;
    font-weight: 600 !important;
    @media (max-width: 768px) {
      font-size: 16px !important;
    }
  }
  /*! CSS Used from: https://www.blacklane.com/_next/static/css/537a07905b9c2ad7.css */
  h2,
  h3,
  h4,
  p,
  ul {
    margin: 0;
    padding: 0;
  }
  h4 {
    font-size: var(--heading3-size);
    font-weight: var(--font-weight-highlight);
    letter-spacing: 0.33px;
    line-height: var(--heading3-line);
    margin-top: var(--milli);
  }
  h4 + p {
    font-size: var(--subtitle-size);
    font-weight: var(--font-weight);
    letter-spacing: 0.28px;
    line-height: var(--subtitle-line);
  }
  h4 + p {
    margin-top: var(--milli);
  }
  a {
    color: var(--color-text-default);
    text-decoration: underline;
  }
  p {
    font-size: 12px !important;
    line-height: 16px !important;
    color: #b4b8c7 !important;
  }
  a:active,
  a:focus,
  a:hover {
    text-decoration: none;
  }
  * {
    scroll-margin-top: 100px;
  }
  ._H2Responsive_1eacj_109 {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.15px;
  }
  @media (min-width: 0px) and (max-width: 767px) {
    ._H2Responsive_1eacj_109 {
      font-size: 28px;
      line-height: 36px;
      letter-spacing: 0.15px;
    }
  }
  ._H3Responsive_1eacj_138 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.15px;
  }
  @media (min-width: 0px) and (max-width: 767px) {
    ._H3Responsive_1eacj_138 {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.15px;
    }
  }
  ._BodyResponsive_1eacj_225 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
  @media (min-width: 0px) and (max-width: 767px) {
    ._BodyResponsive_1eacj_225 {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
    }
  }
  ._defaultTypographyColor_1eacj_356 {
    color: #181a1f;
  }
  /*! CSS Used from: https://www.blacklane.com/_next/static/css/617e02c04b7ab0ac.css */
  .container_container__bgxE0 {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--max-page-width);
    padding: 0 var(--nano);
  }
  @media (min-width: 1200px) {
    .container_container__bgxE0 {
      padding: 0;
    }
  }
  /*! CSS Used from: https://www.blacklane.com/_next/static/css/aaae9ce961e17389.css */
  .staticPageContent_staticPageContentContainer__qnuQP {
    margin: 0 auto;
  }

  .staticPageContent_staticPageContentContainer__qnuQP ol {
    font-size: var(--milli);
    font-weight: var(--font-weight-highlight);
    margin-bottom: var(--mega);
    padding-left: var(--milli);
  }
  .staticPageContent_staticPageContentContainer__qnuQP li {
    margin-bottom: var(--pico);
  }
  .staticPageContent_staticPageContentContainer__qnuQP li:last-child {
    margin-bottom: 0;
  }
  .staticPageContent_staticPageContentContainer__qnuQP h2 {
    font-size: var(--deci);
    font-weight: var(--font-weight);
    line-height: var(--deci);
    margin-bottom: var(--deci);
  }
  .staticPageContent_staticPageContentContainer__qnuQP h3 {
    font-size: var(--milli);
    font-weight: var(--font-weight);
    margin-bottom: var(--deci);
  }
  .staticPageContent_staticPageContentContainer__qnuQP p {
    margin-bottom: var(--deci);
  }
  .staticPageContent_staticPageContentContainer__qnuQP p:last-child {
    margin-bottom: var(--milli);
  }
  .staticPageContent_staticPageContentContainer__qnuQP h4 + p {
    font-size: var(--body-size);
  }
  .staticPageContent_staticPageContentContainer__qnuQP ul {
    margin: 0 0 var(--deci) var(--nano);
  }
  @media (min-width: 1011px) {
    .staticPageContent_staticPageContentContainer__qnuQP {
      padding: 0;
      width: var(--text-block-width);
    }
  }
  .StrToHtml_link__Ei5bX {
    cursor: pointer;
    font-weight: var(--font-weight-highlight);
    letter-spacing: var(--letter-spacing-small);
    line-height: var(--body-line);
    text-decoration: none;
    border-radius: var(--femto);
    padding: 0 var(--atto);
    margin: 0 -4px;
    color: var(--color-interactive-link-default);
    background-image: linear-gradient(
        var(--color-interactive-link-default),
        var(--color-interactive-link-default)
      ),
      linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0));
    background-size: calc(100% - 8px) 1px, 105% 100%;
    background-position: 4px 85%, 0 0;
    background-repeat: no-repeat;
    transition: background-size 0.4s, background-image 0.4s;
  }
  .StrToHtml_link__Ei5bX:focus,
  .StrToHtml_link__Ei5bX:hover {
    background-size: 0 1px, 105% 100%;
    background-image: linear-gradient(
        var(--color-interactive-link-default),
        var(--color-interactive-link-default)
      ),
      linear-gradient(
        var(--color-interactive-link-hover),
        var(--color-interactive-link-hover)
      );
  }
  .StrToHtml_link__Ei5bX:active {
    background-image: linear-gradient(
        var(--color-interactive-link-default),
        var(--color-interactive-link-default)
      ),
      linear-gradient(
        var(--color-interactive-link-press),
        var(--color-interactive-link-press)
      );
  }
  .StrToHtml_ul__cZWhk {
    margin: 0 0 var(--deci) var(--nano);
  }
  .StrToHtml_li__9lISm {
    margin-bottom: var(--pico);
  }
  .StrToHtml_p__dpJBX {
    margin-bottom: var(--nano);
  }
  .StrToHtml_h2__sJZUQ,
  .StrToHtml_h3__6cAPI {
    margin-bottom: var(--deci);
  }
`;
