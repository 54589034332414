import styled from 'styled-components';

export const FaqComponent = styled.div`
  margin-top: 80px;
  min-height: calc(100vh - 481px);
  max-width: 1170px;
  margin: 80px auto 0;
  padding-top: 32px;
  @media (max-width: 1200px) {
    max-width: 100%;
    padding: 32px 15px;
  }
  @media (max-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }

  h1 {
    font-size: 42px;
    margin-bottom: 8px;
    @media (max-width: 768px) {
      font-size: 36px;
      line-height: 36px;
    }
    @media (max-width: 490px) {
      font-size: 30px;
      line-height: 36px;
    }
    @media (max-width: 420px) {
      font-size: 27px;
      line-height: 36px;
    }
  }
  & > p {
    font-size: 18px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
    a {
      color: #065fd4;
    }
  }
  & > div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-bottom: 96px;
    @media (max-width: 768px) {
      margin-top: 32px;
      gap: 12px;
    }
    & > div {
      max-width: calc(50% - 8px);
      width: 100%;
      gap: 12px;
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        max-width: 100%;
        width: 100%;
      }
    }
  }
`;
