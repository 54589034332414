import * as S from './styles';

const CustomRequests = ({ customRequests, deleteCustomRequest }) => {
  return (
    <S.CustomRequest>
      <h2>Custom Requests</h2>
      <div>
        {customRequests.map((elem, i) => (
          <div>
            <p>
              Full Name: <span>{`${elem?.first_name} ${elem?.last_name}`}</span>
            </p>
            <p>
              Email: <span>{elem?.email}</span>
            </p>
            <p>
              Phone: <span>{elem?.phone}</span>
            </p>
            <p className='message'>
              Message: <span>{elem?.message}</span>
            </p>
            <div onClick={() => deleteCustomRequest(elem?.email, elem?.phone)}>
              <p>Delete</p>
            </div>
          </div>
        ))}
      </div>
    </S.CustomRequest>
  );
};

export default CustomRequests;
