import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Row = ({ elem, by_hour }) => {
  const history = useHistory();
  return (
    <div>
      <h3 className={elem?.is_with_return ? 'with_return' : ''}>
        {elem?.is_with_return ? 'With Return' : 'One Way'}
      </h3>
      <p>
        Pick-up: <span>{elem?.from_full_address?.address?.split(',')[0]}</span>
      </p>
      {by_hour ? (
        <p>
          Duration: <span>{elem?.duration} Hours</span>
        </p>
      ) : (
        <p>
          Drop Off: <span>{elem?.to_full_address?.address?.split(',')[0]}</span>
        </p>
      )}
      <p>
        Date:{' '}
        <span>
          {moment(elem?.date_to).format('DD MMM YYYY')}, {elem?.time}
        </span>
      </p>
      <p>
        Price: <span>€{elem?.price_total}</span>
      </p>
      <p>
        Order Id: <span>{elem?.trip_id}</span>
      </p>
      <p>
        Class:{' '}
        <span>
          {elem?.car_type === 'business'
            ? 'Business Class (Mercedes-Benz E-Class)'
            : elem?.car_type === 'van'
            ? 'Business Class (Mercedes-Benz V-Class)'
            : elem?.car_type === 'sclass' &&
              'First Class (Mercedes-Benz S-Class)'}
        </span>
      </p>
      <p>
        Created Order Date:{' '}
        <span>{moment(elem?.date_created).format('DD MMM YYYY')}</span>
      </p>
      {elem?.is_with_return && (
        <>
          <br />
          <span className='span'></span>
          <p>
            Pick-up Return:{' '}
            <span>
              {elem?.from_full_address_return?.address?.split(',')[0]}
            </span>
          </p>
          <p>
            Drop Off Return:{' '}
            <span>{elem?.to_full_address_return?.address?.split(',')[0]}</span>
          </p>
          <p>
            Date Return:{' '}
            <span>
              {moment(elem?.date_return).format('DD MMM YYYY')},{' '}
              {elem?.time_return}
            </span>
          </p>
          <p>
            Class Return:{' '}
            <span>
              {elem?.car_type_return === 'business'
                ? 'Business Class (Mercedes-Benz E-Class)'
                : elem?.car_type_return === 'van'
                ? 'Business Class (Mercedes-Benz V-Class)'
                : elem?.car_type_return === 'sclass' &&
                  'First Class (Mercedes-Benz S-Class)'}
            </span>
          </p>
        </>
      )}
      <svg
        width='64px'
        height='64px'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        onClick={() => history.push(`/admin/${elem?.trip_id}`)}>
        <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
        <g
          id='SVGRepo_tracerCarrier'
          stroke-linecap='round'
          stroke-linejoin='round'></g>
        <g id='SVGRepo_iconCarrier'>
          {' '}
          <path
            d='M5 12H19M19 12L13 6M19 12L13 18'
            stroke='#665cff'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'></path>{' '}
        </g>
      </svg>
    </div>
  );
};

export default Row;
