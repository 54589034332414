import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import * as S from './styles';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
  return (
    <>
      <S.Footer>
        <div>
          <div>
            <img src='/img/logo2.png' alt='Quick Transfer' />
            <p>
              Quick Transfer offers premium transportation services tailored to
              your needs, ensuring comfort, reliability, and professionalism
              every step of the way. From airport transfers to special events,
              trust Quick Transfer for an unparalleled journey.
            </p>
          </div>
          <div>
            <h5>Company</h5>
            <HashLink to='/#book'>Book a Ride</HashLink>
            <Link to='/faq'>FAQ</Link>
            <Link to='/contact-us'>Contact us</Link>
            <Link to='/terms'>Terms</Link>
          </div>
          <div>
            <h5>Contact Details</h5>
            <div>
              <div>
                <h6>Phone</h6>
                <p>+337 8081 6032</p>
              </div>
            </div>
            <div>
              <div>
                <h6>Email Address</h6>
                <p>info@quick-transfer.com</p>
              </div>
            </div>
            <div>
              <div>
                <h6>Location</h6>
                <p>Quarters across France</p>
              </div>
            </div>
            <div>
              <div>
                <h6>Company Name</h6>
                <p>Quick Transfer</p>
              </div>
            </div>
          </div>
        </div>
      </S.Footer>
      <div
        style={{
          background: '#1260cc',
          padding: '10px',
          color: '#fff',
          fontSize: '14px',
          textAlign: 'center',
        }}>
        @ Quick Transfer All Rights Reserved 2024
      </div>
    </>
  );
};

export default Footer;
