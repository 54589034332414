import { useSelector } from 'react-redux';
import * as S from './styles';
import { useEffect, useState } from 'react';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';

const AdminTrip = () => {
  const loggedIn = useSelector((state) => state.params.loggedIn);
  const [info, setInfo] = useState({});
  const history = useHistory();

  const location = useLocation();
  const trip_id = location?.pathname.replace('/admin/', '');

  useEffect(() => {
    if (!loggedIn) {
      history.push('/');
      return;
    } else {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/transfers-custom?trip_id=${trip_id}`
        )
        .then((res) => {
          setInfo(res?.data?.[0]);
        });
    }
  }, []);
  return (
    <S.AdminTrip>
      <Link to='/admin' className='hide-on-print'>
        Go Back
      </Link>
      <h1 className='hide-on-print'>Admin Dashboard</h1>
      <h3>Trip ID: {trip_id}</h3>
      <button className='download' onClick={() => window.print()}>
        Download as PDF
      </button>
      <div>
        <div>
          <p>Pick Up Location</p>
          <p>{info?.from_full_address?.address}</p>
        </div>
        {info?.type_transfer === 'Hour' ? (
          <div>
            <p>Duration</p>
            <p>{info?.duration} Hours</p>
          </div>
        ) : (
          <div>
            <p>Drop Off Location</p>
            <p>{info?.to_full_address?.address}</p>
          </div>
        )}
        <div>
          <p>Date</p>
          <p>
            {moment(info?.date_to).format('DD MMM YYYY')}, {info?.time}
          </p>
        </div>
        <div>
          <p>Persons</p>
          <p>{info?.persons}</p>
        </div>
        <div>
          <p>Class</p>
          <p>
            {info?.car_type === 'business'
              ? 'Business Class (Mercedes-Benz E-Class)'
              : info?.car_type === 'van'
              ? 'Business Class (Mercedes-Benz V-Class)'
              : info?.car_type === 'sclass' &&
                'First Class (Mercedes-Benz S-Class)'}
          </p>
        </div>
        <div>
          <p>Date Created</p>
          <p>{moment(info?.date_created).format('DD MMM YYYY')}</p>
        </div>
        <div>
          <p>Type Transfer</p>
          <p>{info?.type_transfer}</p>
        </div>
        <div className='hide-on-print'>
          <p>Total Price</p>
          <p>€{info?.price_total}</p>
        </div>
        <div className='hide-on-print'>
          <p>Deposit</p>
          <p>€{parseInt(info?.price_total) - parseInt(info?.left_to_pay)}</p>
        </div>
        <div>
          <p>Left to Pay to the Driver</p>
          <p>€{parseInt(info?.left_to_pay)}</p>
        </div>
        <div>
          <p>Full Name</p>
          <p>{`${info?.first_name} ${info?.last_name}`}</p>
        </div>
        <div>
          <p>Email</p>
          <p>{info?.email}</p>
        </div>
        <div>
          <p>Phone Number</p>
          <p>{info?.phone}</p>
        </div>
        <div>
          <p>Meet Name</p>
          <p>{info?.meet_name}</p>
        </div>
        <div>
          <p>Flight Number</p>
          <p>{info?.flight_number}</p>
        </div>
        <div>
          <p>Baby Seat</p>
          <p>{info?.baby_seat ? 'Yes' : 'No'}</p>
        </div>
        <div>
          <p>Extra Stop</p>
          <p>{info?.extra_stop ? 'Yes' : 'No'}</p>
        </div>
        <div>
          <p>Other Services</p>
          <p>{info?.is_other_services ? 'Yes' : 'No'}</p>
        </div>
        <div>
          <p>Other Services Text</p>
          <p>{info?.other_services_text}</p>
        </div>
        <div>
          <p>Notes Chauffeur</p>
          <p>{info?.notes_chauffeur}</p>
        </div>
        <div>
          <p>Other Notes</p>
          <p>{info?.other_notes}</p>
        </div>
      </div>
      {info?.is_with_return && (
        <>
          <h4>Return Information</h4>
          <div>
            <div>
              <p>Pick Up Location</p>
              <p>{info?.from_full_address_return?.address}</p>
            </div>
            <div>
              <p>Drop Off Location</p>
              <p>{info?.to_full_address_return?.address}</p>
            </div>
            <div>
              <p>Date</p>
              <p>
                {moment(info?.date_return).format('DD MMM YYYY')}, {info?.time}
              </p>
            </div>
            <div>
              <p>Class</p>
              <p>
                {info?.car_type_return === 'business'
                  ? 'Business Class (Mercedes-Benz E-Class)'
                  : info?.car_type_return === 'van'
                  ? 'Business Class (Mercedes-Benz V-Class)'
                  : info?.car_type_return === 'sclass' &&
                    'First Class (Mercedes-Benz S-Class)'}
              </p>
            </div>
            <div>
              <p>Flight Number</p>
              <p>{info?.flight_number}</p>
            </div>
            <div>
              <p>Baby Seat</p>
              <p>{info?.baby_seat_return ? 'Yes' : 'No'}</p>
            </div>
            <div>
              <p>Extra Stop</p>
              <p>{info?.extra_stop_return ? 'Yes' : 'No'}</p>
            </div>
            <div>
              <p>Other Services</p>
              <p>{info?.is_other_services_return ? 'Yes' : 'No'}</p>
            </div>
            <div>
              <p>Other Services Text</p>
              <p>{info?.other_services_text_return}</p>
            </div>
          </div>
        </>
      )}
    </S.AdminTrip>
  );
};

export default AdminTrip;
