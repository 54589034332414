import styled from 'styled-components';

export const MainBlock = styled.div`
  padding: 25px 15px 15px;
  border-radius: 0 6px 6px 6px;
  box-shadow: 4px 1px 4px rgba(255, 255, 255, 0.2),
    1px 4px 4px rgba(255, 255, 255, 0.2);
  width: 370px;
  margin-top: 82px;
  position: relative;
  z-index: 2;
  background-color: #f1f2f7;
  position: relative;
  transition: width 0.3s ease-in-out;
  width: 460px;
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 84px;
    border-radius: 0 0 6px 6px;
  }
  .options {
    display: flex;
    position: absolute;
    top: -33px;
    left: 0;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    @media (max-width: 768px) {
      flex-wrap: wrap;
      top: -33px;
      width: 100%;
      & > p {
        max-width: 50%;
        width: 100%;
        background: #ebe6e6 !important;
        color: #3e3935 !important;
        border-radius: 0 !important;
      }
    }
    p {
      color: #fff;
      background: transparent !important;
      padding: 8px 13px;
      font-size: 14px;
      cursor: pointer;
      border-radius: 8px 8px 0 0;
      transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
      @media (max-width: 500px) {
        background: #f7f7f7 !important;
        font-size: 14px !important;
      }
    }
    .active {
      background: #f1f2f7 !important;
      color: #3e3935 !important;
      border-radius: 8px 8px 0 0;
    }
  }
  & > .inputs {
    & > .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      .results {
        width: 340px !important;
        border-radius: 6px !important;
        overflow: hidden;
        @media (max-width: 600px) {
          width: 310px;
          &.results_mobile {
            right: 0;
          }
        }
      }

      & > div {
        max-width: calc(50% - 5px);
        width: 100%;
      }
    }
    .hour {
      top: 26px !important;
      z-index: 1;
    }
    .input_custom {
      position: relative;
      margin-bottom: 12px;
      & > img,
      & > svg {
        max-width: 20px;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 8px;
      }
      [class$='control'] {
        & > div:first-of-type {
          padding-left: 32px !important;
          padding-top: 19px !important;
        }
      }
      [class$='singleValue'] {
        font-size: 14px !important;
      }
      .duration {
        & > div {
          border-width: 0px !important;
        }
      }
      .message_icon {
        transform: none;
        top: 10px;
      }
      & > div {
        position: relative;
        & > img {
          max-width: 20px;
          position: absolute;
          &:first-child {
            left: 32px;
            top: 23.5px;
            cursor: pointer;
          }
          &:last-child {
            left: 82px;
            top: 23px;
            cursor: pointer;
          }
        }
      }
      .results {
        position: absolute;
        z-index: 2;
        top: 8px;
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 6px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        p:first-of-type {
          color: #3e3935 !important;
          margin-bottom: 3px;
        }
        .small {
          font-size: 12px;
          color: #64666b;
        }
        & > div {
          padding: 10px 20px 10px 40px;
          border-bottom: 1px solid #e3e5ea;
          position: relative;
          cursor: pointer;
          background: #fff;
          transition: 0.3s ease-in-out;
          &:hover {
            background: #f1f2f7;
          }
          svg,
          img {
            position: absolute;
            left: 12px;
            top: 10px;
            max-width: 22px;
          }
          &:last-child {
            border-bottom: none;
          }
          .via {
            max-width: 15px;
          }
        }
      }
      & > p {
        position: absolute;
        top: 6px;
        color: #3e3935;
        left: 36px;
        font-size: 12px;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
      & > input,
      & > textarea {
        padding: 25px 15px 6px 35px;
        background: #fff;
        border: 1px solid #f1f2f7;
        width: 100%;
        border-radius: 6px;
        transition: 0.3s ease-in-out;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        font-family: inherit;
        resize: none;
        @media (max-width: 768px) {
          font-size: 15px;
        }
        &:focus {
          background-color: var(--bs-body-bg);
          border-color: #86b7fe;
          box-shadow: 0 0 0 0.25rem #0d6efd40;
          color: var(--bs-body-color);
          outline: 0;
        }
      }
      .PhoneInputCountry {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 10px;
      }
      .PhoneInputCountryIcon--border {
        box-shadow: none;
        background: none;
        border-radius: 2px;
        img {
          border-radius: 2px;
          max-width: 20px;
        }
      }
      .PhoneInputCountrySelectArrow {
        display: none;
      }
      .PhoneInput {
        input {
          padding: 25px 15px 6px 35px;
          background: #fff;
          border: 1px solid #f1f2f7;
          width: 100%;
          border-radius: 6px;
          transition: 0.3s ease-in-out;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          font-family: inherit;
          resize: none;
          &:focus {
            background-color: var(--bs-body-bg);
            border-color: #86b7fe;
            box-shadow: 0 0 0 0.25rem #0d6efd40;
            color: var(--bs-body-color);
            outline: 0;
          }
        }
      }
      & > textarea {
        height: 106px;
        padding-top: 25px;
      }
      textarea:focus + p {
        background: #f1f2f7; /* Change the color to red, for example */
        width: 88%;
        padding-top: 6px;
        top: 1px;
        transition: 0.25s ease-in-out;
      }
      .message {
        width: 88%;
        padding-top: 6px;
        background: #fff;
        top: 1px;
        transition: 0.25s ease-in-out;
      }
      .persons {
        padding-left: 60px;
      }
    }
    .date_input {
      img {
        left: 9px;
      }
    }
  }
  .calendar {
    position: absolute;
    background: #fff;
    z-index: 4;
    border-radius: 6px;
    top: 4px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    overflow: hidden;
    .css-1aj9me-MuiPickersLayout-root {
      border-radius: 6px;
    }

    .MuiClockPointer-thumb,
    .MuiClockPointer-root,
    .MuiClock-pin,
    .MuiClockPointer-root,
    .MuiClockPointer-thumb {
      background-color: #3a9bdc !important;
      border-color: #3a9bdc;
    }
    .MuiClock-root {
      margin-bottom: 0;
    }
    .MuiDialogActions-root {
      padding-top: 0;
    }
    .MuiClock-root {
      margin-top: 4px !important;
    }
    .MuiButtonBase-root {
      color: #3a9bdc;
    }
    .rdp-day_selected,
    .rdp-day_selected:focus-visible,
    .rdp-day_selected:hover {
      background-color: #3a9bdc !important;
    }
    .rdp-day_range_middle {
      opacity: 0.4 !important;
    }
    @media (max-width: 768px) {
      & > div > div {
        padding: 0 0 0px 16px !important;
        .MuiClock-root {
          margin-top: 0 !important;
          margin-bottom: 0 !important;
          margin-left: -20px;
        }
      }
      &.calendar_mobile {
        left: -107%;
      }
    }
  }
  .button {
    width: 100%;
    height: 38px;
    border: none;
    border-radius: 6px;
    background: #3a9bdc;
    color: #fff;
    font-family: Circularpro book, Inter, sans-serif;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    @media (max-width: 768px) {
      font-size: 16px;
    }
    &:hover {
      background: #1260cc;
    }
  }
`;
