import { useSelector } from 'react-redux';
import * as S from './styles';
import moment from 'moment/moment';
import dayjs from 'dayjs';

const Step3 = () => {
  const data = useSelector((state) => state.params.data);
  return (
    <S.Step3>
      <div>
        <h2>Personal Information</h2>
        <div className='row mobile_row'>
          <div>
            <h4>First Name</h4>
            <p>{data?.first_name}</p>
          </div>
          <div>
            <h4>Last Name</h4>
            <p>{data?.last_name}</p>
          </div>
        </div>
        <div className='row mobile_column'>
          <div>
            <h4>Email</h4>
            <p>{data?.email}</p>
          </div>
          <div>
            <h4>Phone Number</h4>
            <p>{data?.phone}</p>
          </div>
        </div>
        {data?.isFlight && (
          <div>
            <h4>Meet Name</h4>
            <p>{data?.meet_name}</p>
          </div>
        )}
      </div>
      <div>
        <h2>
          {data?.tab === 'with_return'
            ? 'Initial Transfer'
            : 'Transfer Details'}
        </h2>
        <div className='row'>
          <div>
            <h4>Pick-up Location</h4>
            <p>{data?.fromFull?.address}</p>
          </div>
          {data?.tab === 'hour' ? (
            <div>
              <h4>Duration</h4>
              <p>{data?.hour} Hours</p>
            </div>
          ) : (
            <div>
              <h4>Drop-off Location</h4>
              <p>{data?.toFull?.address}</p>
            </div>
          )}
        </div>
        <div className='row'>
          <div>
            <h4>Date & Time</h4>
            <p>
              {data?.with_return
                ? `${moment(data?.range.from).format('DD MMM YYYY')}, ${
                    dayjs(data?.time).hour() < 10
                      ? `0${dayjs(data?.time).hour()}`
                      : dayjs(data?.time).hour()
                  }:${
                    dayjs(data?.time).minute() < 10
                      ? `0${dayjs(data?.time).minute()}`
                      : dayjs(data?.time).minute()
                  }`
                : `${moment(data?.range).format('DD MMM YYYY')}, ${
                    dayjs(data?.time).hour() < 10
                      ? `0${dayjs(data?.time).hour()}`
                      : dayjs(data?.time).hour()
                  }:${
                    dayjs(data?.time).minute() < 10
                      ? `0${dayjs(data?.time).minute()}`
                      : dayjs(data?.time).minute()
                  }`}
            </p>
          </div>

          <div>
            <h4>Service</h4>
            <p>
              {data?.typeCar === 'business'
                ? 'Business Class (Mercedes-Benz E-Class)'
                : data?.typeCar === 'van'
                ? 'Business Class (Mercedes-Benz V-Class)'
                : data?.typeCar === 'sclass' &&
                  'First Class (Mercedes-Benz S-Class)'}
            </p>
          </div>
        </div>
        <div className='row'>
          <div>
            <h4>Service Cost</h4>
            <p>€{data?.priceTo}</p>
          </div>
          {data?.isFlight && (
            <div>
              <h4>Flight/Train Number</h4>
              <p>{data?.flight_number}</p>
            </div>
          )}
        </div>
        <div className='row'>
          <div>
            <h4>Persons</h4>
            <p>
              <img src='/img/users.svg' alt='Luggage' /> {data?.persons}
            </p>
          </div>
          {data?.isBabySeat && (
            <div>
              <h4>Baby Seat</h4>
              <p>€20</p>
            </div>
          )}
          {data?.isExtraStop && (
            <div>
              <h4>Extra Stop</h4>
              <p>€20</p>
            </div>
          )}
          {data?.isOtherServices && (
            <div>
              <h4>Extra Services</h4>
              <p>€20</p>
            </div>
          )}
        </div>
        {data?.otherServicesText && (
          <div>
            <h4>Other Services</h4>
            <p>{data?.otherServicesText}</p>
          </div>
        )}
      </div>
      {data?.tab === 'with_return' && (
        <div>
          <h2>Return Transfer Details</h2>
          <div className='row'>
            <div>
              <h4>Pick-up Location</h4>
              <p>{data?.fromFullReturn?.address}</p>
            </div>
            <div>
              <h4>Drop-off Location</h4>
              <p>{data?.toFullReturn?.address}</p>
            </div>
          </div>
          <div className='row'>
            <div>
              <h4>Date & Time</h4>
              <p>{`${moment(data?.range.to).format('DD MMM YYYY')}, ${
                dayjs(data?.timeReturn).hour() < 10
                  ? `0${dayjs(data?.timeReturn).hour()}`
                  : dayjs(data?.timeReturn).hour()
              }:${
                dayjs(data?.timeReturn).minute() < 10
                  ? `0${dayjs(data?.timeReturn).minute()}`
                  : dayjs(data?.timeReturn).minute()
              }`}</p>
            </div>

            <div>
              <h4>Service</h4>
              <p>
                {data?.typeCarReturn === 'business'
                  ? 'Business Class (Mercedes-Benz E-Class)'
                  : data?.typeCarReturn === 'van'
                  ? 'Business Class (Mercedes-Benz V-Class)'
                  : data?.typeCarReturn === 'sclass' &&
                    'First Class (Mercedes-Benz S-Class)'}
              </p>
            </div>
          </div>
          <div className='row'>
            <div>
              <h4>Service Cost</h4>
              <p>€{data?.priceFrom}</p>
            </div>
            <div>
              <h4>Persons</h4>
              <p>
                <img src='/img/users.svg' alt='Luggage' /> {data?.persons}
              </p>
            </div>
          </div>
          {(data?.isBabySeatReturn ||
            data?.isExtraStopReturn ||
            data?.isOtherServicesReturn ||
            data?.otherServicesTextReturn) && (
            <div className='row'>
              {data?.isBabySeatReturn && (
                <div>
                  <h4>Baby Seat</h4>
                  <p>€15</p>
                </div>
              )}
              {data?.isExtraStopReturn && (
                <div>
                  <h4>Extra Stop</h4>
                  <p>€20</p>
                </div>
              )}
              {data?.isOtherServicesReturn && (
                <div>
                  <h4>Extra Services</h4>
                  <p>€20</p>
                </div>
              )}
            </div>
          )}
          {data?.otherServicesTextReturn && (
            <div>
              <h4>Other Services</h4>
              <p>{data?.otherServicesText}</p>
            </div>
          )}
        </div>
      )}
      {(data?.other_notes || data?.notes_chauffeur) && (
        <div>
          <h2>Comments & Additional services</h2>
          <div>
            <h4>Notes for the chauffeur</h4>
            <p>{data?.notes_chauffeur}</p>
          </div>
          <div>
            <h4>Other Notes</h4>
            <p>{data?.other_notes}</p>
          </div>
        </div>
      )}
    </S.Step3>
  );
};

export default Step3;
