import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as S from './styles';
import { useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';

const Header = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [openNav, setOpenNav] = useState(false);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener when the component mounts
    window.addEventListener('resize', handleResize);

    // Remove event listener when the component unmounts to avoid memory leaks
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const history = useHistory();

  return (
    <>
      <S.Header>
        <div>
          <img
            src='/img/logo2.png'
            alt='Milan Transfer'
            onClick={() => history.push('/')}
          />
          {windowWidth >= 768 ? (
            <div>
              <Link to='/'>Home</Link>
              <Link to='/faq'>FAQ</Link>
              <Link to='/contact-us'>Contact us</Link>
              <Link to='/terms'>Terms & Conditions</Link>
            </div>
          ) : (
            <button
              type='button'
              className='chakra-button css-1tei9w9'
              aria-label='Open menu'
              onClick={() => setOpenNav(!openNav)}>
              <span className='chakra-button__icon css-1wh2kri'>
                <span className={openNav ? 'css-1pvzqor' : 'css-za1uc2'} />
              </span>
            </button>
          )}
        </div>
      </S.Header>
      {openNav && (
        <S.Mobiledev>
          <div>
            <Link to='/'>Home</Link>
            <Link to='/faq'>FAQ</Link>
            <Link to='/contact-us'>Contact us</Link>
            <Link to='/terms'>Terms & Conditions</Link>
          </div>
          <div className='bottom'>
            <div>
              <h6>Phone</h6>
              <p>+337 8081 6032</p>
            </div>
            <div>
              <div>
                <h6>Email Address</h6>
                <p>info@quick-transfer.com</p>
              </div>
            </div>
            <div>
              <div>
                <h6>Company Name</h6>
                <p>Quick Transfer</p>
              </div>
            </div>
          </div>
        </S.Mobiledev>
      )}
    </>
  );
};

export default Header;
