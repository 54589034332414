import { createSlice } from '@reduxjs/toolkit';

export const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    message: '',
    style: '', // default is red: other styles: success, info
  },
  reducers: {
    setAlert: (state, action) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { setAlert } = alertSlice.actions;

export default alertSlice.reducer;
