import styled from 'styled-components';

export const Contact = styled.div`
  margin-top: 80px;
  min-height: calc(100vh - 481px);
  max-width: 1170px;
  margin: 80px auto 0;
  padding-top: 32px;
  @media (max-width: 1200px) {
    max-width: 100%;
    padding: 32px 15px;
  }
  @media (max-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }
  h1 {
    font-size: 42px;
    margin-bottom: 8px;
    @media (max-width: 768px) {
      font-size: 36px;
    }
    @media (max-width: 490px) {
      font-size: 30px;
      line-height: 36px;
    }
    @media (max-width: 420px) {
      font-size: 27px;
      line-height: 36px;
    }
  }
  & > p {
    font-size: 18px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
    a {
      color: #065fd4;
    }
  }
  .input_custom {
    position: relative;
    margin-bottom: 12px;
    & > img {
      max-width: 20px;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 8px;
    }
    & > div {
      position: relative;
      img {
        max-width: 20px;
        position: absolute;
        &:first-child {
          left: 32px;
          top: 23.5px;
        }
        &:last-child {
          left: 82px;
          top: 23px;
        }
      }
    }
    p {
      position: absolute;
      top: 6px;
      color: #3e3935;
      left: 16px;
      font-size: 12px;
      font-weight: 600;
    }
    input,
    textarea {
      padding: 25px 15px 6px 15px;
      background: #fff;
      border: 1px solid #f1f2f7;
      width: 100%;
      border-radius: 6px;
      transition: 0.3s ease-in-out;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      resize: none;
      font-family: inherit;
      &:focus {
        background-color: var(--bs-body-bg);
        border-color: #86b7fe;
        box-shadow: 0 0 0 0.25rem #0d6efd40;
        color: var(--bs-body-color);
        outline: 0;
      }
    }
    .persons {
      padding-left: 60px;
    }
  }
  .phone {
    input {
      padding-left: 60px !important;
    }
    .PhoneInputCountry {
      position: absolute;
      left: 17px;
      bottom: 8px;
      .PhoneInputCountryIcon--border {
        box-shadow: none;
        background: none;
        img {
          position: static !important;
        }
      }
    }
  }
  & > div {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 16px;
    margin-top: 32px;
    margin-bottom: 64px;
    position: relative;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .main_block {
      max-width: 50%;
      width: 100%;
      @media (max-width: 768px) {
        max-width: 100%;
      }
      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
        & > div {
          max-width: calc(50% - 6px);
          width: 100%;
        }
      }
      button {
        padding: 15px 16px;
        background: red;
        border-radius: 8px;
        color: #fff;
        background: rgb(2, 0, 36);
        background: linear-gradient(
          90deg,
          rgba(2, 0, 36, 1) 0%,
          rgba(156, 117, 97, 1) 50%,
          rgba(97, 71, 58, 1) 100%
        );
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        transition: 0.3s ease-in-out;
        outline: none;
        border: none;
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        &:hover {
          transform: rotate(1deg);
          cursor: pointer;
        }
      }
      textarea {
        height: 100px;
      }
    }
    .italic {
      font-size: 12px;
      margin-top: 7px;
      color: #64666b;
      position: absolute;
      bottom: -24px;
      @media (max-width: 768px) {
        position: relative;
        bottom: unset;
        margin-bottom: 16px;
      }
    }
    & > img {
      max-width: calc(50% - 12px);
      width: 100%;
      border-radius: 4px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      object-fit: cover;
      object-position: center;
      @media (max-width: 768px) {
        max-width: 100%;
      }
    }
  }
`;
