import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import Homepage from './pages/Homepage';
import Faq from './components/Faq';
import FaqPage from './pages/Faq';
import ContactUsPage from './pages/contact';
import TermsPage from './pages/terms';
import Booking from './components/Booking';
import BookingPage from './pages/booking';
import Admin from './components/Admin';
import AdminTrip from './components/AdminTrip';

const Routes = () => {
  return (
    <Switch>
      <Route
        path='/'
        exact
        render={(props) => {
          return <Homepage {...props} />;
        }}
      />
      <Route
        path='/faq'
        exact
        render={(props) => {
          return <FaqPage {...props} />;
        }}
      />
      <Route
        path='/contact-us'
        exact
        render={(props) => {
          return <ContactUsPage {...props} />;
        }}
      />
      <Route
        path='/terms'
        exact
        render={(props) => {
          return <TermsPage {...props} />;
        }}
      />
      <Route
        path='/booking'
        exact
        render={(props) => {
          return <BookingPage {...props} />;
        }}
      />
      <Route
        path='/admin'
        exact
        render={(props) => {
          return <Admin {...props} />;
        }}
      />
      <Route
        path='/admin/:trip'
        exact
        render={(props) => {
          return <AdminTrip {...props} />;
        }}
      />
    </Switch>
  );
};

export default Routes;
