import * as S from './styles';

const CustomHeroBlock = () => {
  return (
    <S.CustomHero>
      {/* <img src='/img/overlay2.png' alt='overlay' /> */}
      <div>
        <img src='/img/tie.svg' alt='tie' />
        <h3>Class & Proffesionalism</h3>
      </div>
      <div>
        <img src='/img/checked2.svg' alt='tie' />
        <h3>Personalised and Safety</h3>
      </div>
      <div>
        <img src='/img/arrows.svg' alt='wheel' />
        <h3>Hundreds of rides daily</h3>
      </div>
      <div>
        <img src='/img/click.svg' alt='tie' />
        <h3>Easy Booking</h3>
      </div>
    </S.CustomHero>
  );
};

export default CustomHeroBlock;
