import { useState } from 'react';
import * as S from './styles';
import PhoneInput from 'react-phone-number-input';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../slices/alertSlice';

const Step2 = ({ personalInfo, setPersonalInfo, isFlight, data }) => {
  console.log(isFlight);
  return (
    <S.Step2>
      <h2>Personal Information</h2>
      <p>
        Please fill out the fields below with all your information for an
        accurate and smooth travel.{' '}
      </p>
      <div>
        <div className='row'>
          <div className='input_custom'>
            <input
              type='text'
              placeholder='John'
              value={personalInfo?.first_name}
              onChange={(e) =>
                setPersonalInfo({ ...personalInfo, first_name: e.target.value })
              }
            />
            <p>First Name</p>
          </div>
          <div className='input_custom'>
            <input
              type='text'
              placeholder='Doe'
              value={personalInfo?.last_name}
              onChange={(e) =>
                setPersonalInfo({ ...personalInfo, last_name: e.target.value })
              }
            />
            <p>Last Name</p>
          </div>
        </div>
        <div className='input_custom phone'>
          <PhoneInput
            placeholder='Enter phone number'
            value={personalInfo?.phone}
            defaultCountry='IT'
            onChange={(e) => {
              setPersonalInfo({
                ...personalInfo,
                phone: e,
              });
            }}
          />
          <p>Phone Number</p>
        </div>
        <div className='input_custom'>
          <input
            type='text'
            placeholder='john.doe@gmail.com'
            value={personalInfo?.email}
            onChange={(e) =>
              setPersonalInfo({ ...personalInfo, email: e.target.value })
            }
          />
          <p>Email Address</p>
        </div>

        {isFlight && (
          <>
            <p className='infos'>
              The name written on the board when meeting you.
            </p>
            <div className='input_custom'>
              <input
                type='text'
                placeholder='John Doe'
                value={personalInfo?.meet_name}
                onChange={(e) =>
                  setPersonalInfo({
                    ...personalInfo,
                    meet_name: e.target.value,
                  })
                }
              />
              <p>Meet Name</p>
            </div>
          </>
        )}
        <h2 style={{ marginTop: '32px', marginBottom: '18px' }}>
          Additional information
        </h2>
        {isFlight && (
          <>
            <p className='infos'>
              Enter the flight or train number to ensure the chauffeur brings
              the guest to the correct terminal.
            </p>
            <div className='input_custom'>
              <input
                type='text'
                placeholder='U24567'
                value={personalInfo?.flight_number}
                onChange={(e) =>
                  setPersonalInfo({
                    ...personalInfo,
                    flight_number: e.target.value,
                  })
                }
              />
              <p>Flight/Train Number</p>
            </div>
          </>
        )}
        <div className='input_custom'>
          <textarea
            type='text'
            placeholder='Type here...'
            value={personalInfo?.notes_chauffeur}
            onChange={(e) =>
              setPersonalInfo({
                ...personalInfo,
                notes_chauffeur: e.target.value,
              })
            }
          />
          <p>Notes for the chauffeur</p>
        </div>
        <div className='input_custom'>
          <textarea
            type='text'
            placeholder='Type here...'
            value={personalInfo?.other_notes}
            onChange={(e) =>
              setPersonalInfo({ ...personalInfo, other_notes: e.target.value })
            }
          />
          <p>Other Notes</p>
        </div>
      </div>
    </S.Step2>
  );
};

export default Step2;
