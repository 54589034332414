import styled from 'styled-components';

export const Steps = styled.div`
  background-color: #f1f2f7;
  max-width: 100%;
  padding: 42px 0 72px;
  @media (max-width: 768px) {
    padding-top: 0px;
  }
  & > div {
    max-width: 1170px;
    margin: 0 auto;
    @media (max-width: 1220px) {
      padding: 0 15px;
    }
    h2 {
      font-size: 42px;
      margin-bottom: 72px;
      @media (max-width: 768px) {
        font-size: 26px;
        text-align: center;
      }
    }
    & > div {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 64px;
      position: relative;
      @media (max-width: 768px) {
        flex-direction: column;
        gap: 120px;
      }
      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 33%;
        position: relative;
        @media (max-width: 768px) {
          width: 100%;
        }
        .line {
          position: absolute;
          top: 50px;
          right: -50px;
          @media (max-width: 768px) {
            top: 130%;
            right: 43%;
            transform: rotate(90deg);
          }
        }
        & > div {
          margin-bottom: 12px;
          background: #fff;
          padding: 20px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100px;
          height: 100px;
          img {
            max-width: 60px;
            width: 100%;
            margin-bottom: 0;
          }
        }
        img {
          max-width: 60px;
          margin-bottom: 12px;
          width: 100%;
        }
        .lower {
          max-width: 48px;
        }
        h4 {
          margin-bottom: 12px;
          font-size: 20px;
          color: #1260cc;
          font-weight: 600;
        }
        p {
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }
`;
